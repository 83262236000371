import validator from 'validator';

const validatePass = (data) => {
  let errors = {}
  let regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  // let capitalWord = !/[A-Z]/
  // let smallWord = !/[a-z]/


  const { current, newp, confi } = data;

  if (validator.isEmpty(current)) {
    errors.current = '현재 비밀번호를 입력하세요.'
  }
  if (validator.isEmpty(newp)) {
    errors.newp = '새 비밀번호를 입력하세요.'
  }
  if (!newp.match(regularExpression)) {
    errors.newp = '비밀번호는 8~16자로 대문자, 소문자, 숫자 및 특수 문자를 각 하나 이상 포함하여 설정하셔야 합니다.';
  }
  if (!/[A-Z]/.test(newp)) {
    errors.newp = '비밀번호는 8~16자로 대문자, 소문자, 숫자 및 특수 문자를 각 하나 이상 포함하여 설정하셔야 합니다.';
  }
  if (!/[a-z]/.test(newp)) {
    errors.newp = '비밀번호는 8~16자로 대문자, 소문자, 숫자 및 특수 문자를 각 하나 이상 포함하여 설정하셔야 합니다.';
  }
  if (newp.length < 8 || newp.length > 16) {
    errors.newp = '비밀번호는 8~16자로 대문자, 소문자, 숫자 및 특수 문자를 각 하나 이상 포함하여 설정하셔야 합니다.';
  }
  if (validator.isEmpty(confi)) {
    errors.confi = '확인 비밀번호를 입력하세요.'
  }
  return {
    isInvalid: Object.keys(errors).length > 0,
    error: errors
  }
}

export default validatePass;